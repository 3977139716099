@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600;700&display=swap');
@import '~normalize.css';

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #1a1a1a;
}

/* disables error page */
/* iframe {
  display: none;
} */
